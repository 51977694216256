import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { KBT_QUESTION } from '../common/v5/constants';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none ",
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,
	background: isDragging ? "lightgreen" : "grey",
	...draggableStyle
});

//sub droppable style
const getSubDroppableStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "lightblue" : "",
	padding: grid,
	width: 'auto'
});

//displayList is a string of comma separated ids
//referenceList is a complete list of array of objects with id and displayName, subset of this list will be displayed
//todo: do proper props type validation

const DragAndDropBox = ({title, referenceList, displayList, dragEnd, onDelete}) => {
	const listArr = displayList.split(",");
	const listObj = [];
	$.each(listArr, (i, v) => {
		let keyObj = referenceList.find(o => o.id == v);
		if(keyObj) {
			listObj.push({name: keyObj.displayName, id: keyObj.id, pos: i});
		}
	});
	const handleOnDragEnd = (result) => {
		const { destination, source, draggableId } = result;
		const id = parseInt(draggableId);
		if (!destination) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}
		dragEnd(result, destination.index, id);
	}
	const handleDelete = (pos) => {
		onDelete(pos);
	}

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<div id="DragDropKanban" className="dragDrop-context single-drop">
				<div className={"board__container"}>
					<div className="board__title" title={title}>{title}</div>
						<Droppable droppableId="boxes">
							{(provided, snapshot) => (
							<div
								className={classNames("board__body", { "isDraggingOver": snapshot.isDraggingOver })}
								style={getSubDroppableStyle(snapshot.isDraggingOver)}
								ref={provided.innerRef}
								{...provided.droppableProps}
								>
								<div className="board__body-inner-wrap">
									{listObj.map(({id, name, pos}, index) =>
										<Draggable key={id} draggableId={id.toString()} index={index}>
											{(provided, snapshot) => (
											<div
												className={classNames("item", { "isDragging": (snapshot.isDragging && !snapshot.isDropAnimating) })}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												  )}
												ref={provided.innerRef}
												{...provided.dragHandleProps}
												{...provided.draggableProps}>
												<div className={"item__content"}>
													<div className="item__details">
														<i className="icon-grip"></i>
														{name}
													</div>
													<div className="item__remove" onClick={() => handleDelete(pos)}>
														<i className='icon-times'></i>
													</div>
												</div>
											</div>
											)}
										</Draggable>
									)}
									{provided.placeholder}
								</div>
							</div>
							)}
						</Droppable>
				</div>
			</div>
		</DragDropContext>
	)
};

//referenceList is a complete list of array of objects with id, displayName and icon
function getCustomItemStyle(isDragging, draggableStyle) {
	return {
		userSelect: "none ",
		padding: grid * 2,
		margin: `${grid}px 0 ${grid}px 0`,
		background: isDragging ? "lightgreen" : "grey",
		border: "1px solid #ccc",
		...draggableStyle
	}
}

const getBoxStyle = (isDraggingOver, isClickable) => ({
	display: 'flex',
	textAlign: 'center',
	cursor: isClickable ? 'pointer' : 'default',
	textAlign: 'left',
	borderRadius: '5px',
	backgroundColor: isDraggingOver ? '#E0F3FF' : 'inherit',
});

export const CustomDraggableBox = ({
	title,
	referenceList,
	rightIcon,
	innerClass,
	active,
	kbType,
	list,
	displayList,
	nodeIdentifier='id',
	onClickBox,
	onClickSettings,
	onDelete,
	disableDrag,
	showRatings,
	isClickable,
	droppable,
	placeholder,
	disableDrop,
	onClickNodeLink,
	forErrand,
	onShowAnswerForErrand,
	...props
}) => {
	const DEBUGMODE = false; //turn this on when want to debug draggable stuff
	const [listObj, setListObj] = useState([]);
	const [hoveredItem, setHoveredItem] = useState(null);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); 
	
	var agentName = ""; 

	if (kbType === "suggestion") {
		for (let i = 0; i < list.length; i++) {
			if (list[i].agentName) {
				agentName = list[i].agentName;
				break;
			}
		}
	}
	
	
	
	
	
	useEffect(() => {
		const listArr = displayList.split(",");
		const listTemp = [];
		$.each(listArr, (i, v) => {
			let keyObj = referenceList.find(o => o.id == v);
			if (keyObj) {
				let agentName = "";
				if (kbType === "suggestion") {
					const agentObj = list.find(l => l.id === keyObj.id);
					if (agentObj && agentObj.agentName) {
						agentName = agentObj.agentName;
					}
				}
				listTemp.push({
					name: keyObj.displayName,
					agent: agentName,
					id: keyObj.id,
					pos: i,
					type: keyObj.type,
					icon: keyObj.icon,
					draggable: keyObj.draggable,
					droppable: keyObj.droppable,
					droppableType: keyObj.droppableType,
					removable: keyObj.removable,
					nodeId: keyObj.nodeId ? keyObj.nodeId : 0,
					showNext: keyObj.showNext,
					showSettings: keyObj.showSettings,
					showTimeControlled: keyObj.showTimeControlled,
					showLink: keyObj.showLink,
					link: keyObj.link,
					errandId: keyObj.errandId,
					threadId: keyObj.threadId,
					showID: keyObj.showID,
					answer: keyObj.answer,
					question: keyObj.question,
					attachments: keyObj.attachments,
					keyboardShortcut: keyObj.keyboardShortcut,
					keyboardShortcutUseAlt: keyObj.keyboardShortcutUseAlt,
					keyboardShortcutUseCtrl: keyObj.keyboardShortcutUseCtrl,
					keyboardShortcutUseMeta: keyObj.keyboardShortcutUseMeta,
					keyboardShortcutUseShift: keyObj.keyboardShortcutUseShift,
					externalVotes: keyObj.externalVotes,
					voteUp: keyObj.voteUp,
					voteDown: keyObj.voteDown,
					chatbots: keyObj.chatbots,
				});
			}
		});
		setListObj(listTemp);
	}, [displayList, referenceList, kbType, list]);

	const handleDelete = (node, e) => {
		e.stopPropagation();
		onDelete(node);
	}

	const formKeyBoardShortcut = (keys) => {
		let mod = [];
		if( keys.keyboardShortcutUseAlt ) {
			mod.push("Alt");
		}
		if( keys.keyboardShortcutUseCtrl ) {
			mod.push("Ctrl");
		}
		if( keys.keyboardShortcutUseMeta ) {
			mod.push("Meta");
		}
		if( keys.keyboardShortcutUseShift ) {
			mod.push("Shift");
		}
		let mods = mod.join(" + ");
		return "("+mods+" + "+keys.keyboardShortcut+")";
	}

	const handleClickBox = (item) => {
		const {
			id,
			name,
			answer,
			question,
			attachments,
			keyboardShortcut,
			keyboardShortcutUseAlt,
			keyboardShortcutUseCtrl,
			keyboardShortcutUseMeta,
			keyboardShortcutUseShift,
			type,
		} = item;
		onClickBox(id, name, type);
		if(type == KBT_QUESTION && forErrand) {
				const questionText = question;
				const answerText = answer;
				let shortcuts = "";
				if(keyboardShortcut){
					let keys = {
						keyboardShortcut,
						keyboardShortcutUseAlt,
						keyboardShortcutUseCtrl,
						keyboardShortcutUseMeta,
						keyboardShortcutUseShift
					}
					shortcuts = formKeyBoardShortcut(keys);
				}
				const p = {
					questionText,
					answerText,
					attachments,
					id,
					shortcuts
				}
				onShowAnswerForErrand(p, item);

		}
	}
	//pattern of keys/draggable id is index_type_id
	//for example, 0_library_1

	const renderListItem = (item, index, disableDrag) => {
		const nodeID = item[nodeIdentifier] ? item[nodeIdentifier] : item.id;
		const handleMouseEnter = (item, event) => {
			setHoveredItem(item);
			updateTooltipPosition(event); 
		};
		
		const handleMouseMove = (event) => {
			updateTooltipPosition(event);
		};
		
		const handleMouseLeave = () => {
			setHoveredItem(null);
		};
		
		const updateTooltipPosition = (event) => {
			const x = event.clientX ?? 0;
			const y = event.clientY ?? 0;
			setTooltipPosition({ x, y });
		};
		
		
		
		
		return (
			<Draggable
				key={`${index}_${item.type}_${nodeID.toString()}`}
				draggableId={`${index}_${item.type}_${nodeID.toString()}`}
				index={index}
				isDragDisabled={disableDrag}
				type={item.droppableType}
			>
				{(provided, snapshot) => (
					<div
						className={classNames("item", {
							"isDragging": snapshot.isDragging && !snapshot.isDropAnimating,
							"active": active === `${item.type}-${item.id}`,
							"draggable": item.draggable && !disableDrag
						})}
						style={getCustomItemStyle(snapshot.isDragging, provided.draggableProps.style)}
						onClick={() => handleClickBox(item)}
						onMouseEnter={(event) => handleMouseEnter(item, event)}
						onMouseMove={handleMouseMove}
						onMouseLeave={handleMouseLeave}
						ref={provided.innerRef}
						{...provided.dragHandleProps}
						{...provided.draggableProps}
					>
						<Droppable
							key={`droppableInto_${(item[nodeIdentifier] ? item[nodeIdentifier] : item.id).toString()}`}
							droppableId={`droppableInto__${(item[nodeIdentifier] ? item[nodeIdentifier] : item.id).toString()}`}
							isDropDisabled={disableDrop}
							type="innerDroppable"
						>
							{(providedDroppable, snapshotDroppable) => (
								<div
									className={classNames("kb-inner-draggable-box", {
										isDraggingOver: snapshotDroppable.isDraggingOver,
									})}
									ref={providedDroppable.innerRef}
									{...providedDroppable.droppableProps}
									style={{
										minHeight: snapshotDroppable.isDraggingOver ? '150px' : 'unset',
									}}
								>
									<div className="item__content" style={getBoxStyle(snapshot.isDragging, isClickable)}>
										<div className="item__details" style={{ width: '100%', display: 'flex' }}>
											{item.icon}
											{item.draggable && !disableDrag && <i className="icon-grip"></i>}
											{
												DEBUGMODE && (index) + " "
											}
											{
												<span>{item.name}</span>
											}
											{
												item.showID && ' (ID:' + item.id + ')'
											}
											{DEBUGMODE && '---id-----'}
											{DEBUGMODE && item.id}
											{DEBUGMODE && '--nodeid ---'}
											{DEBUGMODE && item.nodeId}
											{
												item.showLink ? <InternalLink agent={item.agent} link={item.link} errandId={item.errandId} threadId={item.threadId} onClick={onClickNodeLink} /> : ''
											}
										</div>
										{item.showSettings &&
											<div className="item__settings" onClick={(e) => onClickSettings(item.id, item.type, e)}>
												<i className='icon-settings'></i>
											</div>
										}
										{item.showTimeControlled &&
											<div className="item__time-controlled">
												<i className='icon-clock'></i>
											</div>
										}
										{showRatings &&
											<div className="item__ratings">
												<i className='icon-thumbs-up'></i> <span className='count'>{(item.externalVotes + item.voteUp)}</span>
												<i className='icon-thumbs-down'></i> <span className='count'>{item.voteDown}</span>
											</div>
										}
										{item.removable &&
											<div className="item__remove" onClick={(e) => handleDelete(item.nodeId, e)}>
												<i className='icon-minus-circle'></i>
											</div>
										}
										{item.showNext &&
											<div className="item__next" onClick={() => handleClickBox(item)}>
												<i className='icon-chevron-right'></i>
											</div>
										}
									</div>
									{
										snapshotDroppable.isDraggingOver && (
											<div className="placeholder-synchro" style={{ height: '120px' }}>
												<div
													style={{
														border: '2px dashed lightgrey',
														height: '80px',
														maxWidth: '90%',
														textAlign: 'center',
														marginLeft: '5%',
														marginTop: '5%',
													}}
												>
													<h3 style={{ marginTop: '8%' }}>{I("Drop here")}</h3>
												</div>
											</div>
										)
									}
								</div>
							)} 
						</Droppable>
					</div>
				)}
			</Draggable>
		);
	};
	const renderList = (listObj, disableDrag) => {
		return (
			<div className="board__body-inner-wrap">
				{listObj.map((item, index) => (
					renderListItem(item, index, disableDrag)
				))}
			</div>
		);
	};
	return (
		<div>
			{renderList(listObj, disableDrag)}
			{disableDrag === true && hoveredItem && hoveredItem.draggable && hoveredItem.type === 'question' && <Tooltip item={hoveredItem} position={tooltipPosition} isExpanded={props.isExpanded} />} {/* Pass tooltip position correctly */}
		</div>
	);
	
	
}

const Tooltip = ({ item, position, isExpanded }) => {
    if (!item) return null;

    const { x, y } = position;
    
    const tooltipRef = React.useRef(null);
    const [tooltipHeight, setTooltipHeight] = useState(0);
    
    useEffect(() => {
        if (tooltipRef.current) {
            setTooltipHeight(tooltipRef.current.offsetHeight);
        }
    }, [item]); 
    const spaceBelow = window.innerHeight - y - 30;
    const spaceAbove = y;

    let adjustedTop;
    if (spaceBelow < tooltipHeight && spaceAbove >= tooltipHeight) {
        adjustedTop = y - tooltipHeight - 50;
    } else {

        adjustedTop = y + (isExpanded ? -50 : 25);
    }

    const adjustedLeft = isExpanded ? x - 90 : Math.min(x - 40, window.innerWidth - 260);

    return (
        <div
            ref={tooltipRef}  
            style={{
                position: 'fixed',
                top: adjustedTop,
                left: adjustedLeft,
                backgroundColor: '#dfdfdf',
                borderRadius: '4px',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1000,
                pointerEvents: 'none',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                color: '#E0E0E0',
                maxWidth: '250px',
                wordWrap: 'break-word',
            }}
        >
            <div style={{ marginBottom: '8px', color: '#6D6D6D', fontWeight: 'bold' }}>
                {item.name}
            </div>
            {item.question && (
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
                    <span style={{ fontSize: '14px', lineHeight: '1', marginTop: '4px', color: '#797979' }}>•</span>
                    <div>
                        <div style={{ marginBottom: '6px', color: '#656565' }} dangerouslySetInnerHTML={{ __html: item.question }}></div>
                        {item.answer && (
                            <div 
                                style={{ fontSize: '12px', color: '#363636', marginLeft: '3px' }}
                                dangerouslySetInnerHTML={{ __html: item.answer }}
                            ></div>
                        )}
                    </div>
                </div>
            )}
            <hr style={{ border: 'none', borderTop: '1px solid #363636', margin: '5px 0' }} />
        </div>
    );
};






CustomDraggableBox.displayName = 'CustomDraggableBox';

const InternalLink = ({ agent, link, errandId, threadId, onClick }) => {
    const handleClick = (e) => {
        e.stopPropagation();
        onClick(errandId, threadId);
    }
    return (
        <React.Fragment>
            <div className="item__link__agent">
                {"By " + agent + "   "}
            </div>
            <div className="item__link" onClick={handleClick}>
                {"#" + link}
            </div>
        </React.Fragment>
    );
}

export default DragAndDropBox;
