import React, {
	PureComponent,
	forwardRef,
	useCallback,
	useMemo,
	useState
} from 'react';
import onClickOutside from 'react-onclickoutside';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { I } from '../common/v5/config.js';
import { mouseClickCursor } from '../common/helpers';
import { TXT_ACCESS_KEY_EXPIRY, TXT_NO_EXPIRY } from '../common/texts';
import { getChevronIcon } from '../common/v5/helpers';
import Anchor from './Anchor';
import { createUnclickable } from './hocs';
import { InputText } from './Input';
import flexbox from '../styles/flexbox';

import 'react-datepicker/dist/react-datepicker.css';

const dateFormat = 'YYYY/MM/DD';

const withNotClickable = createUnclickable("onClick", "notClickable");

class CustomInputCalendarBase extends React.Component {
	render () {
		const {
				button
				, customClass
				, 'data-qa-id': dataQAId
				, forwardedRef
				, notClickable
				, onClick
			} = this.props
			;
		let { defaultValue: value } = this.props;
		if (this.props.value) {
			value = this.props.value;
		}
		if (button) {
			return (
				<li
					ref={forwardedRef}
					data-qa-id={dataQAId}
					className={customClass}
					onClick={onClick}
					onChange={this.handleChange}
				>
					<span><i className="icon-clock" /></span>
					<span>{value}</span>
				</li>
			);
		} else {
			return (
				<span ref={forwardedRef}>
					<Anchor
						className={customClass}
						onClick={onClick}
						readOnly={notClickable}
					>
						{value}
					</Anchor>
				</span>
			);
		}
	}
}

const RawCustomInputCalendar = withNotClickable(CustomInputCalendarBase);

const CustomInputCalendar = forwardRef((props, ref) => <RawCustomInputCalendar {...props} forwardedRef={ref} />);

CustomInputCalendar.propTypes = {
	onClick: PropTypes.func
	, value: PropTypes.string
	, defaultValue: PropTypes.string // this field sort of use as empty 'value' replacement
};

export default class Calendar extends React.Component {
	constructor (props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleCustomChange = this.handleCustomChange.bind(this);
		this.handleManualReset = this.handleManualReset.bind(this);
	}
	handleChange(date) {
		this.props.onChange(date);
	}
	handleCustomChange(){
		this.refs.CustomDatePicker.setOpen(false);
		this.props.handleCustomChange(this.props.selected);
	}
	handleManualReset(){
		if(this.props.onReset){
			this.props.onReset();
		}
	}
	loadResetLink(){
		if(this.props.selected){
			return(
				<a className="customUpdateButton" onClick={this.handleManualReset}>{I("Reset")}</a>
				)
		}
	}
	render() {
		const {
				readOnly
				,'data-qa-id': dataQAId
				, ...p
			} = this.props
			;
		let selectedDate = p.selected;
		let customClass = "customCalendarInput";
		if(p.customClass) {
			customClass = p.customClass;
		}
		if (p.position && p.position == "bottomLeft") {
			return (
				<DatePicker
					ref={"CustomDatePicker"}
					minDate={moment()}
					customInput={
						<CustomInputCalendar
							button={this.props.button}
							customClass={customClass}
							defaultValue={this.props.defaultValue}
							notClickable={readOnly}
							data-qa-id={dataQAId}
						/>
					}
					selected={selectedDate}
					dateFormat={dateFormat}
					onChange={this.handleChange}
					shouldCloseOnSelect={false}
					popperPlacement="bottom-end"
					popperModifiers={{
						offset: {
							enabled: true,
							offset: '30px, 10px'
						}
					}}
					disabled={this.props.disabled}
				>
					<div onClick={this.handleCustomChange}>
						{this.props.children}
					</div>
					<div>{this.loadResetLink()}</div>
				</DatePicker>
			);
		} else {
			return (
				<div className="datepickerWrapper">
					<DatePicker
						minDate={moment()}
						customInput={
							<CustomInputCalendar
								button={this.props.button}
								customClass={customClass}
								defaultValue={this.props.defaultValue}
								notClickable={readOnly}
							/>
						}
						selected={selectedDate}
						dateFormat={dateFormat}
						isClearable={true}
						onChange={this.handleChange}
						popperModifiers={{
							offset: {
								enabled: true,
								offset: '-48px, 0px'
							}
						}}
					/>
				</div>
			);
		}
	}
}

export class DefaultCalendar extends React.Component {
	constructor (props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(date) {
		this.props.onChange(date);
	}
	render() {
		const {
				button
				, defaultValue
				, readOnly
				, selected
				, disabled
			} = this.props
			;
			let placement = "bottom-start"; //original default value
			if(this.props.mobile) {
				placement = "auto";
			}
		return (
			<div className="datepickerWrapper">
				<DatePicker
					dateFormat={dateFormat}
					selected={selected}
					onChange={this.handleChange}
					customInput={
						<CustomInputCalendar
							button={button}
							customClass="customCalendarInput"
							defaultValue={defaultValue}
							notClickable={readOnly}
						/>
					}
					customInputRef="forwardedRef"
					popperPlacement={placement}
					disabled={disabled}
				/>
			</div>
		);
	}
}

DefaultCalendar.propTypes = {
	selected: PropTypes.instanceOf(moment)
	, onChange: PropTypes.func
};

export class Calender extends PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	componentDidMount() {
		if (!this.props.selected) {
			// TODO: search code do not embrace the React control component
			// fully where the initial search parameter is NOT populated
			// even though there is surely a default search parameter used
			// when doing the first search.
			// this.props.onChange(moment().format(dateFormat));
		}
	}
	handleChange(dateInMoment) {
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(dateInMoment.format(dateFormat));
		}
	}
	render() {
		const { title, style, selected: s, onChange, ...props } = this.props;
		let selected;
		if (s) {
			selected = moment(s, dateFormat);
		} else {
			selected = null;
		}
		return (
			<div style={style}>
				{title}
				<DefaultCalendar {...props}
					defaultValue={I("Select date")}
					selected={selected}
					onChange={this.handleChange}
				/>
			</div>
		);
	}
}

Calender.propTypes = {
	selected: PropTypes.string
	, onChange: PropTypes.func
};

const laterCalenderPadding = {paddingLeft: '12px'}
	, fromCalender = 'fromCalender'
	, toCalender = 'toCalender'
	;

class CalenderPairItem extends PureComponent {
	constructor(props) {
		super(props);
		this.handleFromChange = this.handleFromChange.bind(this);
		this.handleToChange = this.handleToChange.bind(this);
	}
	handleFromChange(...args) {
		this.props.onFromDateChange(...args);
	}
	handleToChange(...args) {
		this.props.onToDateChange(...args);
	}
	render() {
		let intervalClass = 'date-interval'
			, { fromDate, toDate } = this.props
			;
		if (!this.props.show) {
			intervalClass = 'hidden ' + intervalClass;
		}
		return (
			<li className={intervalClass}>
				<Calender title={I("From")}
					selected={fromDate}
					id={fromCalender}
					onChange={this.handleFromChange}
				/>
				<Calender title={I("To")}
					style={laterCalenderPadding}
					selected={toDate}
					id={toCalender}
					onChange={this.handleToChange}
				/>
			</li>
		);
	}
}

const calenderTitleStyle = {cursor: 'pointer'}; //{cursor: 'pointer', color: '#0c87f7'};

class RawVerticalCalenderPair extends PureComponent {
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	handleClickOutside() {
		if (this.props.show && typeof this.props.onToggle === 'function') {
			this.props.onToggle();
		}
	}
	render() {
		const {
			selectedFromDate
			, selectedToDate
			, show
			, title
			, onToggle
			, onChangeFromDate
			, onChangeToDate
		} = this.props;
		return (
			<div>
				<span style={calenderTitleStyle} onClick={onToggle}>
					{title} &nbsp;
					<i className={getChevronIcon(show)} />
				</span>
				<ul className="interval">
					<CalenderPairItem fromDate={selectedFromDate}
						toDate={selectedToDate}
						show={show}
						onFromDateChange={onChangeFromDate}
						onToDateChange={onChangeToDate}
					/>
				</ul>
			</div>
		);
	}
};

export const VerticalCalenderPair = onClickOutside(RawVerticalCalenderPair);

class RawSingleCalender extends PureComponent {
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleClickOutside(){
		if (this.props.show && typeof this.props.onToggle === 'function') {
			this.props.onToggle();
		}
	}
	handleChange(...args) {
		this.props.onSelect(...args);
	}
	render() {
		const {
			id
			, selected
			, title
			, onToggle
			, mobile
			, readOnly
		} = this.props;

		let calenderType;

		if (id === "showDateFrom") {
			calenderType = fromCalender;
		} else {
			calenderType = toCalender;
		}

		return (
			<div className="date-select-dropdown" style={{display: "flex"}} >
				<span style={calenderTitleStyle} onClick={onToggle}>
					{title}
					{/* <i className={getChevronIcon(show)} /> */}
				</span>
				<Calender
					// title={I("From")}
					selected={selected}
					id={calenderType}
					onChange={this.handleChange}
					mobile={mobile}
					readOnly={readOnly}
				/>
			</div>
		)
	}
}

export const SingleCalenderDD = onClickOutside(RawSingleCalender);

const timePickerPopperModifier = {
	offset: {
		enabled: true,
		offset: "30px"
	}
}

const timePickerFormat = 'HH:mm'

const useStringToMoment = (s, format) => useMemo(() => {
	if (s) {
		return moment(s, format)
	}
	return null
}, [s, format])

const useMomentToString = (callback, format) => useCallback(date => {
	callback(date.format(format))
}, [callback, format])

export const TimePicker = ({ onChange, selected, ...props }) => (
	<DatePicker
		customInput={<InputText />}
		dateFormat={timePickerFormat}
		onChange={useMomentToString(onChange, timePickerFormat)}
		popperModifiers={timePickerPopperModifier}
		showTimeSelect
		selected={useStringToMoment(selected, timePickerFormat)}
		showTimeSelectOnly
		timeFormat={timePickerFormat}
		timeCaption={I('Time')}
		timeIntervals={15}
		{...props}
	/>
)

TimePicker.propTypes = {
	selected: PropTypes.string,
	onChange: PropTypes.func
}

const dateWithoutYearFormat = 'MM/DD'

export const DateWithoutYear = ({ onChange, selected, ...props }) => (
	<DatePicker
		customInput={<InputText />}
		dateFormat={dateWithoutYearFormat}
		dateFormatCalendar='MMMM'
		onChange={useMomentToString(onChange, dateWithoutYearFormat)}
		selected={useStringToMoment(selected, dateWithoutYearFormat)}
		{...props}
	/>
)

DateWithoutYear.propTypes = {
	selected: PropTypes.string,
	onChange: PropTypes.func
}

const useMomentToUnixTimestamp = callback => useCallback(
	date => {
		let timestamp
		if (date) {
			timestamp = date.unix()
		} else {
			timestamp = 0
		}
		callback(timestamp)
	},
	[callback]
)

const useUnixTimestampToMoment = timestamp => useMemo(() => {
	if (timestamp > 0) {
		return moment.unix(timestamp)
	}
	return null
}, [timestamp])

const dateValueGetter = value => {
	if (value) {
		return TXT_ACCESS_KEY_EXPIRY.replace('{DATE}', value)
	}
	return TXT_NO_EXPIRY
}

const StyledDiv = styled.div`
	${flexbox}
	${({ disabled }) => mouseClickCursor(disabled)}
`
const StyleI = styled.i`
	font-size: 16px;
`
const IconicDate = forwardRef((
	{ className, disabled, onClick, value },
	ref
) => (
	<StyledDiv disabled={disabled} ref={ref} onClick={onClick}>
		<StyleI className='icon icon-calendar-plus' />
		<span>{dateValueGetter(value)}</span>
	</StyledDiv>
))

const StyledDatePickerDiv = styled.div`
	flex-grow: 2;
	.react-datepicker, .react-datepicker-wrapper {
		.react-datepicker__input-container {
			${flexbox}
			.react-datepicker__close-icon {
				&:after {
					bottom: 2px;
					font-size: 16px;
					font-weight: normal;
					height: 16px;
					line-height: 0.9;
					margin: unset;
					right: unset;
					width: 16px;
				}
			}
		}
	}
`
const useStartOfDayDate = () => {
	const startOfDay = moment().startOf('day').unix()
	return useMemo(() => moment.unix(startOfDay).toDate(), [startOfDay])
}

export const IconTimestampPicker = ({
	className,
	onChange,
	selected,
	...props
}) => (
	<StyledDatePickerDiv>
		<DatePicker
			customInput={<IconicDate />}
			dateFormat='YYYY/MM/DD'
			isClearable
			minDate={useStartOfDayDate()}
			onChange={useMomentToUnixTimestamp(onChange)}
			selected={useUnixTimestampToMoment(selected)}
			{...props}
		/>
	</StyledDatePickerDiv>
)

IconTimestampPicker.propTypes = {
	selected: PropTypes.number,
	onChange: PropTypes.func
}
