// Common components from administration views
import React, { Fragment, memo, useCallback } from 'react'
import { branch, renderComponent, withProps } from 'recompose'
import each from 'lodash/each'
import classNames from 'classnames'
import styled from 'styled-components'
import { emptyObject } from '../common/constants'
import { I } from '../common/v5/config'
import { ADMIN_TITLE, ADMIN_EDIT_TITLE, BTN_TXT_SAVE } from '../common/v5/constants'
import {
  centionGrey,
  centionYellow,
  centionBlue,
  fontSizeAdminForm
} from '../styles/_variables'
import { useCallbackWithValue, useCallbackMultiValues } from '../hooks/callback'
import {
  composeWithDisplayName,
  withUnmountWhenHidden
} from './hocs'
import Button from './Button'
import { Hideable as HideableComponent } from './CFlag'
import { TableIconicButton } from './Form'
import Helper from './Helper'
import { PlainSwitchCheckbox } from './SwitchCheckbox'

export const ACTION_ACTIVATE = 1
export const ACTION_DELETE = 2
export const ACTION_EDIT = 3
export const ACTION_DOWNLOAD = 4
export const ACTION_EXPAND = 5
export const ACTION_RELOAD = 6
export const ACTION_COLLAPSE = 7
export const ACTION_COLLAPSE_EXPAND = 8
export const ACTION_ADD = 9
export const ACTION_PREVIEW_STRUCTURE =  10;
export const ACTION_REMOVE = 11;

const TXT_DELETE = I('Delete')
const TXT_DOWNLOAD = I('Download')

const actions = {
  [ACTION_COLLAPSE]: {
    hoverColor: `${centionYellow}`,
    iconClass: 'icon-minimize',
    title: I('Collapse')
  },
  [ACTION_DELETE]: {
    hoverColor: 'red',
    iconClass: 'icon-trash',
    title: TXT_DELETE
  },
  [ACTION_EXPAND]: {
    hoverColor: `${centionYellow}`,
    iconClass: 'icon-open-ext',
    title: I('Expand')
  },
  [ACTION_DOWNLOAD]: {
    hoverColor: `${centionBlue}`,
    iconClass: 'icon-download',
    title: I('Download')
  },
  [ACTION_EDIT]: {
    hoverColor: `${centionYellow}`,
    iconClass: 'icon-edit',
    title: I('Edit')
  },
  [ACTION_EXPAND]: {
    hoverColor: `${centionYellow}`,
    iconClass: 'icon-open-ext',
    title: I('Expand')
  },
  [ACTION_RELOAD]: {
    hoverColor: `${centionYellow}`,
    iconClass: 'icon-reload',
    title: I('Reload')
  },
  [ACTION_ADD]: {
    hoverColor: `${centionYellow}`,
    iconClass: 'icon-add',
    title: I('Add')
  },
  [ACTION_PREVIEW_STRUCTURE]: {
    hoverColor: `${centionBlue}`,
    iconClass: 'icon-structure',
    title: I('View structure')
  },
  [ACTION_REMOVE]: {
    hoverColor: 'red',
    iconClass: 'icon-minus-circle',
    title: TXT_DELETE
  },
}

const IconicButton = ({ className, hoverColor, ...props }) => (
  <span className={className}>
    <TableIconicButton {...props} />
  </span>
)

const StyledIconicActionButton = styled(IconicButton)`
  :focus {
    outline: none;
  }
  button {
    background-color: transparent;
    border: none;
    color: gray;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    font-size: 18px;
    :focus {
      outline: none;
    }
    :hover {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`
const getHoverColor = type => {
  const action = actions[type]
  if (!action || !action.hoverColor) {
    return 'gray'
  }
  return action.hoverColor
}

const ActionBase = ({ className, disabled, label, onClick, type }) => (
  <StyledIconicActionButton
    {...actions[type]}
    className={className}
    disabled={disabled}
    hoverColor={getHoverColor(type)}
    iconPosition="left"
    label={label}
    onClick={useCallback(e => {
      onClick(e)
      e.stopPropagation()
    }, [onClick])}
    type='button'
  />
)

const Action = composeWithDisplayName(
  'Action',
  memo,
  withProps(({ type }) => {
    if (!actions[type]) {
      return { hidden: true }
    }
  }),
  withUnmountWhenHidden
)(ActionBase)

const createWithAction = type => Component => ({ onClick, ...props }) => (
  <Component
    onClick={useCallbackWithValue(type, onClick)}
    type={type}
    {...props}
  />
)

const createAction = type => createWithAction(type)(Action)

export const Delete = createAction(ACTION_DELETE)
export const Download = createAction(ACTION_DOWNLOAD)
export const Edit = createAction(ACTION_EDIT)
export const Expand = createAction(ACTION_EXPAND)
export const Reload = createAction(ACTION_RELOAD)
export const Collapse = createAction(ACTION_COLLAPSE)
export const CustomAdd = createAction(ACTION_ADD)
export const PreviewStructure = createAction(ACTION_PREVIEW_STRUCTURE)
export const Remove = createAction(ACTION_REMOVE)

const CollapseOrExpand = branch(
  ({ expanded }) => !expanded,
  renderComponent(Expand)
)(Collapse)

const Activate = ({ onClick, ...props }) => (
  <PlainSwitchCheckbox
    onClick={useCallbackWithValue(ACTION_ACTIVATE, onClick)}
    {...props}
  />
)

const ActionComponents = {
  [ACTION_ACTIVATE]: Activate,
  [ACTION_DELETE]: Delete,
  [ACTION_DOWNLOAD]: Download,
  [ACTION_EDIT]: Edit,
  [ACTION_EXPAND]: Expand,
  [ACTION_RELOAD]: Reload,
  [ACTION_REMOVE]: Remove,
  [ACTION_PREVIEW_STRUCTURE]: PreviewStructure,
  [ACTION_COLLAPSE_EXPAND]: CollapseOrExpand
}

export const ActionsWrapper = props => (
  <div className='transparent table-btns' {...props} />
)

const getDisabled = (button, disabledCondition) => {
  if (!disabledCondition) {
    return
  } else if (typeof disabledCondition === 'function') {
    return disabledCondition(button)
  }
  return disabledCondition[button]
}

const getProps = (propsGetter, action) => {
  if (typeof propsGetter === 'function') {
    const result = propsGetter(action)
    if (result) {
      return result
    }
  }
  return emptyObject
}

export const Actions = ({
  actions,
  disabledActions,
  onClick,
  propsGetter,
  ...props
}) => {
  const components = []
  each(actions, v => {
    const Component = ActionComponents[v]
    if (Component) {
      components.push(
        <Component
          key={v}
          disabled={getDisabled(v, disabledActions)}
          onClick={onClick}
          {...getProps(propsGetter, v)}
          {...props}
        />
      )
    }
  })
  return <ActionsWrapper>{components}</ActionsWrapper>
}

const AddBase = ({ active, onClick, hideAddIcon }) => (
  <TableIconicButton
    type='button'
    hide={hideAddIcon}
    className={classNames('btn bg-transparent btn-round', { active })}
    iconClass='icon-add'
    title={I('Create new')}
    onClick={onClick}
  />
)

const Add = withUnmountWhenHidden(AddBase)

//todo-tk: make generic or use className
const StyledDownload = styled(Download)`
  & {
    button {
      display: flex;
      flex-direction: row-reverse;
      .tbl-icon-label {
        font-size: ${fontSizeAdminForm};
      }
      * + * {
        padding-right: 5px;
      }
    }
  }
`
export const LabeledDownload = props => (
  <StyledDownload label={TXT_DOWNLOAD} {...props} />
)

const StyledDelete = styled(Delete)`
  & {
    button {
      display: flex;
      flex-direction: row-reverse;
      .tbl-icon-label {
        font-size: ${fontSizeAdminForm};
      }
      * + * {
        padding-right: 5px;
      }
    }
  }
`
export const LabeledDelete = props => (
  <StyledDelete label={props.label ? props.label : TXT_DELETE} {...props} />
)

const TableHeaderRight = ({
  active,
  children,
  className,
  hideAddIcon,
  onClickAdd
}) => (
  <div className={className}>
    {children}
    <Add active={active} hidden={hideAddIcon} onClick={onClickAdd} />
  </div>
)

export const TableHeader = ({
  addActive,
  children,
  hideAddIcon,
  onClickAdd,
  title
}) => (
  <div className='admin-top-header'>
    <h2 className='float-left'>{title}</h2>
    <TableHeaderRight
      active={addActive}
      className='float-right'
      hideAddIcon={hideAddIcon}
      onClickAdd={onClickAdd}
    >
      {children}
    </TableHeaderRight>
    <div className='clear-both' />
  </div>
)

const StyledDiv = styled.div`
  height: 100%;
`
export const Wrapper = ({ className, idClass,...props }) => (
  <StyledDiv id={idClass} className={classNames('adminWrapper', className)} {...props} />
)

export const ListsWrapper = props => <div className='adminList section' {...props} />

const ListHeaderWrapperBase = ({ className, ...props }) => (
  <div className={classNames('adminListTop', className)} {...props} />
)

export const ListHeaderWrapper = composeWithDisplayName(
  'ListHeaderWrapper',
  memo,
  withUnmountWhenHidden
)(ListHeaderWrapperBase)

export const ListContentWrapper = ({ children, className }) => (
  <div className={classNames('table-wrapper', className)}>
    <div className='inner-content'>{children}</div>
  </div>
)

export const EditorWrapper = props => <div className='adminEdit section' {...props} />

export const EditorWrapperFullWidth = props => <div className='adminEdit inner' {...props} />

export const editorTitlePrefix = (
  isNew,
  title
) => (isNew ? I('Add new') + " " : I('Manage') + " " + title)

export const editorTitlePrefixAdmin = (
  isNew,
  title
) => (isNew ? I('Add new') + " " : I('Edit file'))

export const editorTitleByViewFA = (
  isNew,
  view
) => editorTitlePrefixAdmin(isNew, ADMIN_EDIT_TITLE[view])


export const editorTitleByView = (
  isNew,
  view
) => editorTitlePrefix(isNew, ADMIN_EDIT_TITLE[view])

const EditorHeaderBase = ({ isNew, onClickClose, title }) => (
  <div className='admin-heading edit'>
    <div className='col-split-left'>
      <span className='admin-icons left mb-0'>
        <i
          className={classNames({ 'icon-edit': !isNew }, { 'icon-add': isNew })}
        />
      </span>
      <h5 className='d-inline'>{title}</h5>
    </div>
    {
      onClickClose && (
        <div className='col-split-right'>
          <TableIconicButton
            type='button'
            className='btn bg-transparent'
            iconClass='icon-times'
            title={I('Close form')}
            onClick={onClickClose}
          />
        </div>
      )
    }
  </div>
)

export const EditorHeader = withUnmountWhenHidden(EditorHeaderBase)

const EditorHeaderWDownloadBase = ({ isNew, onClickClose, title, download }) => (
  <div className='admin-heading edit'>
    <div className='col-split-left'>
      <span className='admin-icons left mb-0'>
        <i
          className={classNames({ 'icon-edit': !isNew }, { 'icon-add': isNew })}
        />
      </span>
      <h5 className='d-inline'>{title}</h5>
    </div>
    <div className='col-split-right'>
	<div title={I('Download')}>
		{download}
	</div>
      <TableIconicButton
        type='button'
        className='btn bg-transparent'
        iconClass='icon-times'
        title={I('Close form')}
        onClick={onClickClose}
      />
    </div>
  </div>
)

export const EditorHeaderWDownload = withUnmountWhenHidden(EditorHeaderWDownloadBase)

const StandardEditorHeaderBase = ({
  edit: { which, isNew },
  onClose,
  titleMap
}) => (
  <EditorHeader
    isNew={isNew}
    onClickClose={onClose}
    title={editorTitlePrefix(isNew, titleMap[which])}
  />
)

export const StandardEditorHeader = composeWithDisplayName(
  'StandardEditorHeader',
  memo,
  withProps(({ edit }) => ({ hidden: !edit })),
  withUnmountWhenHidden
)(StandardEditorHeaderBase)

export const EditorBox = props => <div className='form-wrapper clearfix' {...props} />

export const Skeleton = ({ view, children, idClass, className, lists, extraComponent }) => (
  <Wrapper idClass={idClass} className={className}>
    {extraComponent}
    <ListsWrapper>{lists}</ListsWrapper>
    <EditorWrapper id={view}>{children}</EditorWrapper>
  </Wrapper>
)

export const SingleListSekeleton = ({ view, children, listHeader, listContent, editor }) => (
  <Skeleton
    view={view}
    lists={
      // eslint-disable-next-line react/jsx-fragments
      <Fragment>
        <ListHeaderWrapper>{listHeader}</ListHeaderWrapper>
        <ListContentWrapper>{listContent}</ListContentWrapper>
        {!listContent && <EditorWrapperFullWidth>{editor}</EditorWrapperFullWidth>}
      </Fragment>
    }
  >
    {children}
  </Skeleton>
)

const Div = styled.div`
  display: inline-flex;
  width: 100%;
  div {
    &.col-split-left, &.col-split-right {
      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`
const EditorFooterWrapperBase = props => (
  <Div className='form-button-container label-row' {...props} />
)

const EditorFooterWrapper = withUnmountWhenHidden(EditorFooterWrapperBase)

const EditorFooterLeft = props => (
  <div className='col-split-left' {...props} />
)

const EditorFooterRight = props => (
  <div className='col-split-right' {...props} />
)

export const EditorFooter = ({ children, className, hidden, left }) => (
  <EditorFooterWrapper hidden={hidden}>
    <EditorFooterLeft>{left}</EditorFooterLeft>
    <EditorFooterRight>{children}</EditorFooterRight>
  </EditorFooterWrapper>
)

const CancelButtonBase = ({ className, ...props }) => (
  <Button
    className={classNames('cancel', className)}
    color='white'
    text={I('Cancel')}
    {...props}
  />
)

export const CancelButtonOld = styled(CancelButtonBase)`
  & {
    background: #fff;
    color: ${centionGrey};
    &.cancel {
      border: none;
    }
  }
`

export const CancelButton = props => (
  <Button
    className='save-button'
    color='grey'
    text={I('Cancel')}
    {...props}
  />
)

export const SaveButton = ({text, ...props}) => (
  <Button
    className='save-button'
    color='blue'
    text={text ? text : I('Save')}
    {...props}
  />
)

export const FooterButton = ({
  cancel,
  save,
  saveTxt = BTN_TXT_SAVE,
  disableSave = false
}) =>(
	<div className="form-group editPageActionButton">
		<div className="row">
			<div className="col-lg-12 textAlignRight">
				<CancelButton onClick={cancel} />
				<SaveButton
					key="btn-save-admin"
          text={saveTxt}
					onClick={save}
          disabled={disableSave}
				/>
			</div>
		</div>
	</div>)

export const CopyButton = props => (
  <Button
    className='save-button'
    color='blue'
    text={I('Copy')}
    {...props}
  />
)

export const HideableDiv = withUnmountWhenHidden('div')

export const Hideable = HideableComponent

const FullWidthDiv = styled.div`
  div& {
    width: 100%;
    &.double-decks {
      .top {
        font-weight: 800;
        padding: 5px;
      }
      .bottom {
        display: flex;
        padding: 0px 5px 5px;
        .main {
          width: calc(100% - ${({ noRightEnd }) => noRightEnd ? '0px' : '35px'});
        }
        .right-end {
          width: 35px;
        }
      }
    }
  }
`
const withDoubleDecksBase = Component => ({
  children,
  className,
  noRightEnd,
  text,
  ...props
}) => (
  <FullWidthDiv
    className={classNames(className, 'double-decks')}
    noRightEnd={noRightEnd}
  >
    <FullWidthDiv className='top'><b>{text}</b></FullWidthDiv>
    <FullWidthDiv className='bottom'>
      <div className='main'>{children}</div>
      {!noRightEnd && <Component className='right-end' {...props} />}
    </FullWidthDiv>
  </FullWidthDiv>
)

export const RightEndHelper = ({ helper, ...props }) => (
  <Helper {...props}>{helper}</Helper>
)

export const withDoubleDecks = composeWithDisplayName(
  'withDoubleDecks',
  withUnmountWhenHidden,
  memo,
  withDoubleDecksBase
)

export const DoubleDecks = withDoubleDecks(RightEndHelper)


export const AdminListAndEditLayout = ({ idClass, children, className, listSection, editSection, extraComponent }) => (
  <Wrapper id={idClass} className={className}>
    {extraComponent}
    {
      listSection && <ListsWrapper>{listSection}</ListsWrapper>
    }
    <EditorWrapper>{editSection}</EditorWrapper>
  </Wrapper>
)

const SubmitButton = ({ className, text }) => {
	return (<button
		type="submit"
		className={className}
		title={text}
		data-qa-id="btn-Save"
	>{text}</button>
	)
}

const EditFormButtons = ({
	disableSave,
	hideDelete,
	onCancel,
	onDelete,
	onSave
}) => (
	<EditorFooter
		left={<LabeledDelete hidden={hideDelete} onClick={onDelete} />}
	>
		<CancelButton onClick={onCancel} />
		{/* <SaveButton disabled={disableSave} onClick={onSave} type="submit" /> */}
		<SubmitButton className={"btn-blue"} text={I('Save')} />
	</EditorFooter>
)

const EditFormBase = Component => ({
	// areaList,
	activeId,
	input,
	view,
	// onHandleTextInputChange,
	// onHandleTextInputBlur,
	onChangeAdminInput,
	isNew,
	onSave,
	onCancel,
	onDelete,
	hidden,
	...props
}) => {
	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		onCancel={onCancel}
		onDelete={useCallbackMultiValues(onDelete, activeId)}
		onSave={onSave}
	/>
	return (
		<EditorBox>
			<Component
				activeId={activeId}
				input={input}
				view={view}
				hidden={hidden}
				onChangeAdminInput={onChangeAdminInput}
				isNew={isNew}
				baseButtons={baseButtons}
				onSave={onSave}
				onCancel={onCancel}
				onDelete={onDelete}
        {...props}
			/>
		</EditorBox>
	)
}

export const withEditWrap = composeWithDisplayName(
	'EditForm',
	memo,
	withProps(({ activeId }) => {
		if (!activeId) {
			return { isNew: true }
		}
		return { isNew: false }
	}),
	withUnmountWhenHidden,
	EditFormBase
)

export const CustomButton = ({ className, disabled, text, icon, onClick }) => {
	return (
		<Fragment>
			<button
				type="button"
				className={className}
				title={text}
				data-qa-id="btn-Save"
				onClick={onClick}
				disabled={disabled}
			>
			{ icon && <i className={icon}></i> }
			{text}
			</button>
		</Fragment>
	)
}
