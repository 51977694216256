import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { I } from '../common/v5/config';

/**
 * A simple select component that accept a single dimension array of strings
 * and a callback function to handle the selected item.
 * with a search bar to filter the list. 
**/

const useOutsideClick = (callback) => {
	const ref = useRef();
	useEffect(() => {
		const handleClick = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		};
		document.addEventListener('click', handleClick, true);
		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [ref]);
	return ref;
};

const SelectFilter = ({ withToggle = false, toggleTitle = I("Select"), list, onClickList }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [showSearch, setShowSearch] = useState(!withToggle);

	const handleClickOutside = () => {
		setShowSearch(false);
	};

	const ref = useOutsideClick(handleClickOutside);

	const handleToggleSearch = () => {
		setShowSearch(!showSearch);
	};

	const handleSearchChange = (event) => {
		event.preventDefault();
		setSearchTerm(event.target.value);
	};

	let filteredList = [];

	if (list && list.length > 0) {
		filteredList = list.filter(tag =>
			tag.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}

	const toggleStyle = {
		color: '#6d6d6d',
		fontWeight: 'bold',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		fontSize: '12px',
		padding: '8px 0 8px 5px'
	}

	return (
		<div className = "history-box-content">
			{withToggle && (
				<div onClick={handleToggleSearch} style={toggleStyle}>
					{toggleTitle} <i className={showSearch ? 'icon-chevron-up' : 'icon-chevron-down'} style={{ marginLeft: '0.5em' }} />
				</div>
			)}
			{showSearch && (
				<div ref={ref} className="select-filter-with-search">
					<i className="icon-search"></i>
					<input
						type="text"
						placeholder="Search errand..."
						value={searchTerm}
						onChange={handleSearchChange}
					/>
					<ul>
						{filteredList.map((tag, index) => (
							<li key={index} onClick={() => onClickList(tag)}>{tag}</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default SelectFilter;
