import React, { PureComponent, useCallback, useMemo, useState } from 'react';
import each from 'lodash/each';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from 'react-avatar-edit';
import { Collapse, CardBody, Card } from 'reactstrap';
import { useDisableableCallback } from '../hooks/callback';
import { withUnmountWhenHidden }  from './hocs';
import { SelectionBox } from './SelectBox';
import {default as SquareCheckbox, NamedCheckbox}  from './SquareCheckbox';
import Button from './Button';
import {
	convertToYesOrNoId,
	isNumberOrString,
	noYesOption
} from '../common/helpers';
import { emptyObject } from '../common/constants';
import { UNSELECT } from '../common/v5/constants';
import { createMarkup, truncateHtml } from '../common/v5/helpers';
import flexbox from '../styles/flexbox';
import cursor from '../styles/pointer';
import { buttonPadding, centionBlue } from '../styles/_variables';
import Helper from '../reactcomponents/Helper'

const centeredAlign = {textAlign: "center"};

const RowBase = ({ children }) => (
	<div className="label-row">
		<div className="label-block">{children}</div>
	</div>
);

export const Row = withUnmountWhenHidden(RowBase);

const LabelBase = ({ children, className, htmlFor }) => (
	<div className={className}>
		<label htmlFor={htmlFor}>{children}</label>
	</div>
);

export const Label = withUnmountWhenHidden(LabelBase);

export const withRow = LabelComponent => ({
	className,
	children,
	htmlFor,
	label,
	...props
}) => (
	<Row {...props}>
		<LabelComponent className={className} htmlFor={htmlFor} label={label} />
		{children}
	</Row>
);

const TextLabel = ({ label, ...props }) => (
	<Label {...props} hidden={!label}>
		{label}
	</Label>
)

const LabelRow = withRow(TextLabel)

const StyledDiv = styled.div`
	${flexbox}
	align-items: flex-start;
`
const readOnlyWrapperStyle = {
	pointerEvents: "none",
	opacity: 0.7
};

export const withLabel = Component => ({
	id,
	className,
	components,
	hide,
	innerClassName,
	label,
	helperID,
	helperTxt,
	readonly,
	'data-qa-id': dataQAID,
	...props
}) => (
	<LabelRow className={"label-"+id} hidden={hide} htmlFor={id} label={label}>
		<StyledDiv direction='column'>
			<div
				className={className}
				style={readonly ? readOnlyWrapperStyle : emptyObject}
				data-qa-id={dataQAID}
			>
				<Component {...props} className={innerClassName} />
				{helperTxt && <HelperTooltip txt={helperTxt} id={helperID} />}
			</div>
			{components}
		</StyledDiv>
	</LabelRow>
);

// TODO: use react transition group to build animate react component for
// spinner. This can't use css class because it'll be loaded as another page
// which do not include custom css / font.
const BigSpinner = () => {
	return <div style={centeredAlign}><h1>Loading ... </h1></div>;
};

const StyleInput = styled.input`
	input& {
		background: ${centionBlue};
		border: none;
		color: white;
		padding: ${buttonPadding};
	}
`
const SubmitBase = ({ text }) => <StyleInput type="submit" value={text} />

const Submit = withUnmountWhenHidden(SubmitBase)

const trashKey = '{"trash":"'

const noData = '"}'

const convertIfJSON = (params, json) => {
	if (!json) {
		return params
	}
	let value
	if (params) {
		// https://github.com/keithhackbarth/submitAsJSON
		// https://systemoverlord.com/2016/08/24/posting-json-with-an-html-form.html
		value = '",' + JSON.stringify(params).replace(/^{/g, '')
	} else {
		value = noData
	}
	return { [trashKey]: value }
}

const StyledDivWrapForm = styled.div`
	div& {
		form {
			${flexbox}
			input[type="submit"]${StyleInput} {
				width: unset;
			}
		}
	}
`
const AnyForm = ({
	children,
	encType,
	direction,
	formRef,
	hasSubmit,
	json,
	method,
	onSubmit,
	params,
	loading,
	submitFirst,
	text,
	url
}) => {
	const inputs = useMemo(() => {
		const inputs = [];
		each(convertIfJSON(params, json), (v, k) => {
			inputs.push(<input key={k} type="hidden" name={k} value={v} />);
		});
		return inputs;
	}, [json, params]);
	const submit = <Submit hidden={!hasSubmit} text={text} />
	const first = submitFirst ? submit : inputs
	const last = submitFirst ? inputs : submit
	return (
		<StyledDivWrapForm direction={direction}>
			{loading && <BigSpinner />}
			<form
				encType={encType}
				ref={formRef}
				action={url}
				method={method}
				onSubmit={onSubmit}
				target="_self"
			>
				{first}
				{children}
				{last}
			</form>
		</StyledDivWrapForm>
	);
};

export const JSONForm = props => (
	<AnyForm encType='text/plain' json {...props} />
)

// const Form = props => {
// 	const ref = useRef(null)
// 	const { current } = ref
// 	useEffect(() => {
// 		if (current) {
// 			current.submit();
// 		}
// 	}, [current])
// 	return <AnyForm formRef={ref} {...props} />
// }

// TODO: refactor to use AnyForm ^.
class Form extends PureComponent {
	constructor(props) {
		super(props);
		this.setFormRef = this.setFormRef.bind(this);
	}
	componentDidMount() {
		this.formRef.submit();
	}
	componentWillUnmount() {
		// console.log("dbg form component unmount");
	}
	setFormRef(ref) {
		this.formRef = ref;
	}
	render() {
		const { method, params, loading, url } = this.props;
		let inputs = [];
		$.each(params, (k, v) => {
			inputs.push(<input key={k} type="hidden" name={k} value={v} />);
		});
		return (
			<div>
				{loading && <BigSpinner />}
				<form
					ref={this.setFormRef}
					action={url}
					method={method}
					target="_self"
				>
					{inputs}
				</form>
			</div>
		);
	}
}

export const TextField = React.forwardRef(({
	className
	, value
	, label
	, mainClassName
	, name
	, text
	, warning
	, type
	, mandatory
	, helperTxt
	, placeholder
	, textArea
	, textIcon
	, textIconTitle = ""
	, maxLength
	, overrideClassName
	, readonly
	, onClickTextIcon
	, withTextCounter
	, counterMaxLength = 1500
	, ...props
}, ref) => (
	<div className={classNames("label-block", mainClassName)}>
		{
			label &&
				<div className={"label-"+className}>
					<label htmlFor={className}>{label}<span hidden={mandatory ? false : true} className="mandatory"> * </span></label>
				</div>
		}
		<div className={className+(overrideClassName ? " "+ overrideClassName : "")}>
			<div className="input-wrap">
			{textArea ? <textarea data-qa-id={"textarea-field-input-"+name} placeholder={placeholder} className={"text-field"} type={type ? type : "text"} name={name} value={value} readOnly={readonly} ref={ref} {...props} />
			: <input data-qa-id={"text-field-input-"+name} autoComplete="new-password" placeholder={placeholder} className={"text-field"} type={type ? type : "text"} name={name} value={value} title={value} maxLength={maxLength ? maxLength : ""} readOnly={readonly} ref={ref} {...props} />
			}
			{textIcon &&
				<i className={textIcon} onClick={onClickTextIcon} title={textIconTitle}></i>
			}
			{helperTxt && <Helper {...props}><div dangerouslySetInnerHTML={{ __html: helperTxt }}/></Helper>}
			</div>
			{warning ? <p className="mandatory">{warning}</p> : ""}
			<small className="report-field-tips">{text}</small>
		</div>
		{
			withTextCounter && value && value.length > 0 &&
			<div className="input-text-counter">
				<span>{value.length}/{counterMaxLength}</span>
			</div>
		}
	</div>
));

TextField.defaultProps = { onClickTextIcon: () => {} };

//ToFix: maybe can refine props
export const ReadOnlyTextField = ({id, className, children, value, label, name, text, warning, helperTxt, hidden, ...props}) => {
	return <div className="label-block" hidden={hidden}>
			{label &&
					<div className={" label-"+ id}>
						<label htmlFor={id}>
							{label}
						</label>
					</div>
			}
			<div className='input-container'>
			<div className={"readonly-text helper"}>
				<span data-qa-id={"text-field-input-"+name} className={"text-field "+className}>{value}</span>
				{warning ? <p className="mandatory">{warning}</p> : ""}
				<small className={"text-info " + className}>{text}</small>
			</div>
			{children} {/*useful for button */}
			{helperTxt && <Helper {...props}>{helperTxt}</Helper>}
			</div>
		</div>;
};

export const ClickableLableWithHelper = ({className, childrenClass, label, onClick, helperTxt}) => {
	return <div className={className} style={{display: "flex", justifyContent: "space-between", width: "max-content"}}>
		<a className={childrenClass} href="#" onClick={onClick}>
			{label}
		</a>
		{helperTxt && <Helper>{helperTxt}</Helper>}
	</div>
}
export class ReadOnly extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const {items, id, multiple, nested, idFields, ...props} = this.props;
		let r = "";
		if(multiple){
			let ids = [], names = [];
			if (typeof id === "string") {
				ids = id.split(",");
			}
			if(!nested){
				$.each(ids, (i, v) => {
					$.each(items, (n, b) => {
						let key = "id", value = "name";
						if(idFields){
							key = idFields.id;
							value = idFields.name;
						}
						if(b[key] === parseInt(v, 10)){
							names.push(b[value]);
						}
					});
				});
				r = names.join(",");
			}else{
				let subs = [];
				$.each(items, (n, gr) => {
					$.each(gr[nested.key], (i, sub) => {
						subs.push(sub);
					});
				});
				$.each(ids, (n, id) => {
					$.each(subs, (i, v) => {
						if(v[idFields.id] === parseInt(id, 10)){
							names.push(v[idFields.name]);
						}
					});
				});
				r = names.join(",");
			}
		}else{
			$.each(items, (i, v) => {
				if(v.id === id) {
					if(!multiple){
						r = v.name
						return false;
					}
				}
			});
		}
		return (
			<div className="text-field">
				<label className="readonly-input"> {r} </label>
			</div>
		);
	}
}

export const PostForm = ({ method, ...props }) => <Form {...props} method="post" />;

export const ReadOnlyInputRow = ({
	children
	, className
	, hide
	, label
	, value
	, ...props
}) => {
	return (
		<div className="label-row" hidden={hide}>
			<div className="label-block">
				<div className="label-validName">
					<label htmlFor="validName">{label}</label>
				</div>
				<div className="loginname">
					<span>{value}</span>
					<span id="agentFoundSuccess" className="glyphicon form-control-feedback" aria-hidden="true"></span>
					<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
				</div>
			</div>
		</div>
	);
}

//FIXME: position
export const HelperTooltip = ({
	id,
	name,
	position,
	txt,
	onClick,
	...props
}) => {
	return (
		<div className={"helper-tooltip "+position}>
			<span href="#" data-tooltip="">
				<i className="fas fa-info-circle"></i>
				<div className="tooltip-content">
					<div className="" dangerouslySetInnerHTML={createMarkup(txt)} />
				</div>
			</span>
		</div>
		)
}

export const TextInputRow = React.forwardRef(({
	type
	, id
	, name
	, placeholder
	, className
	, hide
	, label
	, value
	, warning
	, helperTxt
	, icon
	, textIcon
	, maxLength
	, onChange
	, onBlur
	, textArea
	, inlineLabel = true
	, readonly
	, ...props
}, ref) => (
	<div className={classNames("label-row", { notInline: !inlineLabel })} hidden={hide}>
		{
			icon &&
				<i className={icon}></i>
		}
		<TextField id={id}
			type={type}
			name={name}
			label={label}
			placeholder={placeholder}
			textIcon={textIcon}
			value={value}
			warning={warning}
			helperTxt={helperTxt}
			maxLength={maxLength}
			className={className}
			onChange={onChange}
			onBlur={onBlur}
			textArea={textArea}
			ref={ref}
			readonly={readonly}
			{...props}
		/>
	</div>
));

TextInputRow.propTypes = {
	id: PropTypes.string,
	// value: PropTypes.string,
	textArea: PropTypes.bool
};

//input with custom component
export const CustomInputRow = ({
	id
	, name
	, className
	, hide
	, label
	, mandatory
	, children
	, helper
	, ...props
}) => {
	return (
		<div className="label-row" hidden={hide}>
			<div className="label-block">
				{
					label &&
						<div className={"label-"+id}>
							<label htmlFor={id}>
								{label}
								{mandatory && <span className="mandatory"> * </span>}
							</label>
						</div>
				}
				<div className={classNames(className, "custom")}
					data-qa-id={"custom-field "+id}
				>
					{children}
				</div>
				{helper && <Helper {...props}>{helper}</Helper>}
			</div>
		</div>
	)
}
// const SelectInputRowBase = withLabel(SelectionBox)
//
// export const SelectInputRow = ({ id, name, option, yesNo, ...props }) => (
// 	<SelectInputRowBase
// 		className={classNames(className, "select-row")}
// 		data-qa-id={"select-input-row-"+id}
// 		helperID={"select-input-form-helper-"+name}
// 		id={id}
// 		name={name}
// 		options={yesNo ? noYesOption : option}
// 		{...props}
// 	/>
// );

// TODO: refactor to use withLabel ^.
export const SelectInputRow = ({
	id
	, name
	, className
	, hide
	, label
	, manualHeight
	, helperTxt
	, option
	, yesNo
	, value
	, readonly
	, textNoItemSelected
	, onSelect
	, selectNone
	, imgId
	, imgSrc
	, imgStyle
	, mandatory
	, warning
	, helper
	, ...props
}) => {
	let opt = option, showImage = false;
	let selectStyle = {};
	if (yesNo) {
		opt = noYesOption;
	}
	if (readonly) {
		selectStyle = {
			pointerEvents: "none",
			opacity: 0.7
		}
	}
	
	if(typeof imgSrc != 'undefined' && imgSrc != ""){
		showImage = true;
	}
	return (
		<div className="label-row" hidden={hide}>
			<div className="label-block">
				{
					label &&
						<div className={"label-"+id}>
							<label htmlFor={id}>
								{label}
								{mandatory && <span className="mandatory"> * </span>}
							</label>
						</div>
				}
				<div
					className={classNames(className, "select-row")}
					style={selectStyle}
					data-qa-id={"select-input-row-"+id}
				>
					<SelectionBox
						manualHeight={manualHeight}
						name={name}
						onSelect={onSelect}
						options={opt}
						selected={isNumberOrString(value) ? value : UNSELECT}
						textNoItemSelected={textNoItemSelected}
						selectNone={selectNone}
					/>
					{showImage  &&
						<img 
							name={imgId}
							id={imgId}
							src={imgSrc}
							style={imgStyle}
						/>
					}
					{warning ? <p className="mandatory">{warning}</p> : ""}
					{/* {helperTxt &&
						<HelperTooltip
							txt={helperTxt}
							id={"select-input-form-helper-"+name}
						/>
					} */}
				{helperTxt && <Helper {...props}>{helperTxt}</Helper>}
				{helper && <Helper {...props}>{helper}</Helper>}
				</div>
			</div>
		</div>
	)
}

SelectInputRow.propTypes = {
	name: PropTypes.string,
	readonly: PropTypes.bool
};

const useChangeFirstArgumentToBoolean = callback => useCallback(
	(value, ...args) => callback(value !== 0, ...args),
	[callback]
)

// 'onSelect' will callback using boolean type which is consistent with input
// 'value' type.
export const BooleanSelect = ({ className, label, name, onSelect, value }) => (
	<SelectInputRow
		id={name}
		name={name}
		className={classNames(className, { [name]: !className })}
		label={label}
		yesNo={true}
		value={convertToYesOrNoId(value)}
		onSelect={useChangeFirstArgumentToBoolean(onSelect)}
	/>
)

export const CheckboxInputRow = ({
	id
	, name
	, className
	, hide
	, label
	, checked
	, "data-qa-id": dataQAId
	, onChange
	, helperTxt
	, disabled
	, width
	, ...props
}) => {
	const customWidth = width ? width: "";
	return (
		<div className="label-row" hidden={hide} style={{width: customWidth}}>
			<div className="label-block">
				<div className={"label-"+className}>
					<label htmlFor={id}>{label}</label>
				</div>
				<div className={className}>
					<SquareCheckbox onClick={onChange} checked={checked} data-qa-id={dataQAId} disabled={disabled}/>
					{helperTxt && <Helper {...props}>{helperTxt}</Helper>}
				</div>
			</div>
		</div>
		)
}

export const FormInputWithLabelRow = ({
	id
	, hide
	, mandatory
	, warning
	, className
	, label
	, mainClassName
	, inlineLabel = true
	, labelHelper //label helper is a tooltip right next to the input label
	, helperTxt //helper text is a tooltip right next to the input
	, children
	, ...props
}) => {
	const infoElement = <i className="icon-alert" style={{transform: "rotate(180deg)", marginLeft: "1rem", fontSize: "14px"}}></i>;
	return (
		<div className={classNames("label-row", mainClassName,  { notInline: !inlineLabel })}  hidden={hide}>
			<div className={classNames("label-block inline-rows", { "with-helper": helperTxt })}>
				<div className={"label " + className}>
					<label htmlFor={id}>
						{label}
						<span hidden={mandatory ? false : true} className="mandatory"> * </span>
						{labelHelper && <Helper triggerElement={infoElement} {...props}>{labelHelper}</Helper>}
					</label>
				</div>
				<div className={className}>
					{children}
					{warning ? <p className="mandatory">{warning}</p> : ""}
				</div>
				{helperTxt && <Helper {...props}>{helperTxt}</Helper>}
			</div>
		</div>
		)
}

export class AvatarInputRow extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const {
		id
		, className
		, name
		, hide
		, label
		, preview
		, handleRemoveAvatar
		, inlineLabel = true
		, ...props } = this.props;

		let avatarPreview = "", uploadAvatar = "";
		if(preview != ""){
			avatarPreview = (
				<img src={preview} />
			);
		}
		return (
			<FormInputWithLabelRow label={label} className={classNames(name, { notInline: !inlineLabel })} >
				<div className="upload-photo">
					<div className="label-block">
						{ !this.props.disabled && <Avatar
							className={className}
							label={I('Choose a file')}
							labelStyle={{fontSize: "12px"}}
							{...props}
						/>
						}
						<div className="value-block avatar-image">
							<div className="avatar-image-preview">
								{avatarPreview}
							</div>
							<div className="avatar-set-photo">
								{!this.props.disabled && preview != "" && <span className="pull-right">
										<Button key="btn-remove-avatar" color="blue" title={I("Remove avatar")} text={"x"} className=""
											style={{padding: "5px 10px", fontSize: "12px"}} onClick={handleRemoveAvatar} />
									</span>
								}
							</div>
						</div>
					</div>
				</div>
			</FormInputWithLabelRow>
		)
	}
}

const StyledI = styled.i`
	${cursor}
	&::before {
		background-color: ${({ active }) => active ? '#7ac0ff4f' : '#eaeaea'};
		height: 20px;
		min-width: 20px;
		text-align: center;
		border-radius: 100px;
		font-size: 12px;
		font-weight: 800;
		line-height: 20px;
		padding: 5px;
	}
`
export const CircledIcon = ({ disabled, onClick, ...props }) => (
	<StyledI
		disabled={disabled}
		onClick={useDisableableCallback(disabled, onClick)}
		{...props}
	/>
)

export const TableIconicButton = ({
	disabled
	, title
	, id
	, label
	, className
	, iconClass
	, iconPosition = "right"
	, iconSize = "unset"
	, type = "button"
	, hide
	, onClick
	, value
	, buttonTxtColor= "unset"
}) => (
	<button
		id={id}
		disabled={disabled}
		hidden={hide}
		className={className}
		title={title}
		data-qa-id={"table-btn-"+title}
		type={type}
		onClick={onClick}
		value={value}
		style={{flexDirection: (iconPosition === "left" ? "row-reverse" : "row")}}
	>
		{ label && <span className="label tbl-icon-label" style={{color: buttonTxtColor}}>{label}</span> }
		<i className={iconClass} style={{fontSize: iconSize}} />
	</button>
);

TableIconicButton.propTypes = {
	title: PropTypes.string
	, disabled: PropTypes.bool
	, label: PropTypes.string
	, className: PropTypes.string
	, iconClass: PropTypes.string
	, iconPosition: PropTypes.string //"left | right"
	, iconSize: PropTypes.string //"NNpx"
	, hide: PropTypes.bool
	, onClick: PropTypes.func
	, value:  PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.number
	])
};

export const CollapsableMenu = ({id, "data-qa-id": dataQAId, label, show, children}) => {

	const [isOpen, setIsOpen] = useState(show);
	const toggle = () => setIsOpen(!isOpen);

	let arrowIcon = isOpen ? "icon-chevron-up" : "icon-chevron-down";
	return (
		<div id={id} className="collapse-menu__wrapper">
			<div className={classNames("collapse-menu__row",{"active": isOpen})}
				data-qa-id={dataQAId}
				onClick={toggle}
			>
				<span>{label}</span>
				<i className={arrowIcon}/>
			</div>
			<Collapse isOpen={isOpen}>
				<Card>
					<CardBody>
						{children}
					</CardBody>
				</Card>
			</Collapse>
		</div>
	)
}
